<template>
  <div class="flex flex-col about_us bg-white width-full height-full">
    <div class="flex flex-col w-full">
      <div class="w-full">
        <img class="w-full" :src="image" />
      </div>
      <div>
        <div class="pb-8">
          <div class="md:m-4 md:pt-8 about-header">
            <h4 class="font-bold text-2xl lg:text-5xl py-10">เกี่ยวกับเรา</h4>
          </div>
          <div class="text-sm md:text-2xl leading-relaxed px-4">
            <span>
              เราดำเนินธุรกิจจำหน่ายรถยนต์มือสองมากว่า 10 ปี ต่อมาในปี 2557
              <br />ได้มีการจดทะเบียนจัดตั้งบริษัท ภายใต้ชื่อ ราชประดิษฐ์ออโต้
              โดยยึดมั่นบริการลูกค้าด้วยใจ
              <br />และประกอบการค้าอย่างตรงไปตรงมา
              ตามความตั้งใจที่อยากให้ลูกค้า
              <br />ได้ใช้รถที่ดีทั้งด้านคุณภาพ
              สอดคล้องกับราคาที่สมเหตุสมผล
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="m-4 py-8 why-header rounded-lg">
          <h4 class="text-2xl md:text-4xl">ทำไมต้องเลือกราชประดิษฐ์ออโต้?</h4>
        </div>
        <div class="grid md:grid-flow-col">
          <div class="grid md:grid-flow-col">
            <div class="py-4 md:py-8 m-2 md:m-4 rounded-lg">
              <div class="h-20">
                <div class="car-box h-full w-full"></div>
              </div>
              <div>
                เราจัดจำหน่ายรถยนต์มือสอง
                <br />ด้วยความซื่อสัตย์โปร่งใส
                <br />เพื่อให้ลูกค้าได้รถที่ดีมีคุณภาพ
              </div>
            </div>
            <div class="py-4 md:py-8 m-4 rounded-lg bg-gray-200">
              <div class="h-20 p-2">
                <div class="tool-box h-full w-full"></div>
              </div>
              <div>
                รับประกันคุณภาพ
                <br />รถยนต์ทุกคันของเรา
                <br />ไม่มีชนหนัก และจมน้ำ
              </div>
            </div>
            <div class="py-4 md:py-8 m-2 md:m-4 rounded-lg">
              <div class="h-20">
                <div class="handshake-box w-full h-full"></div>
              </div>
              <div>
                เป็นสถานประกอบการ
                <br />ที่มีการจดทะเบียนในรูปแบบบริษัท
                <br />ชัดเจน และตรวจสอบได้
              </div>
            </div>
            <div class="py-4 md:py-8 m-4 rounded-lg bg-gray-200">
              <div class="h-20 p-2">
                <div class="user-circle-box w-full h-full"></div>
              </div>
              <div>
                เราให้บริการลูกค้า 24 ชั่วโมง
                <br />เพื่อให้ลูกค้าได้รับบริการที่ดีที่สุด
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: [String],
      default:
        "https://firebasestorage.googleapis.com/v0/b/rajpraditauto.appspot.com/o/files%2Fabout_us.JPG?alt=media&token=13fc03f5-5dd3-4e96-ad4d-174862ed0c2e",
    },
  },
};
</script>

<style scoped>
.about_us .about-header {
  text-shadow: darkblue 2px 2px;
}

.about_us .why-header {
  color: white;
  background-color: rgb(24, 41, 88);
}

.car-box {
  background-color: #464646;
  -webkit-mask: url("../assets/icon/car-solid.svg") no-repeat center;
  mask: url("../assets/icon/car-solid.svg") no-repeat center;
}

.tool-box {
  background-color: #464646;
  -webkit-mask: url("../assets/icon/tools-solid.svg") no-repeat center;
  mask: url("../assets/icon/tools-solid.svg") no-repeat center;
}

.quote-left-box {
  background-color: #464646;
  -webkit-mask: url("../assets/icon/quote-left-solid.svg") no-repeat center;
  mask: url("../assets/icon/quote-left-solid.svg") no-repeat center;
}

.quote-right-box {
  background-color: #464646;
  -webkit-mask: url("../assets/icon/quote-right-solid.svg") no-repeat center;
  mask: url("../assets/icon/quote-left-solid.svg") no-repeat center;
}

.handshake-box {
  background-color: #464646;
  -webkit-mask: url("../assets/icon/handshake-regular.svg") no-repeat center;
  mask: url("../assets/icon/handshake-regular.svg") no-repeat center;
}

.user-circle-box {
  background-color: #464646;
  -webkit-mask: url("../assets/icon/user-circle-solid.svg") no-repeat center;
  mask: url("../assets/icon/user-circle-solid.svg") no-repeat center;
}
</style>
